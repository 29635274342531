import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import LockIcon from '@mui/icons-material/Lock';
import images from 'src/config/images';
import { sequenceEnrollmentStatus } from 'src/modules/contacts/config';
import { capitalizeFirstLetter } from 'src/utils/helper';
import moment from 'moment';
import { dateFormat } from 'src/config';

export const getStepTitle = (type) => {
  switch (type) {
    case 'wait':
      return 'Wait Period';

    case 'email':
      return 'Automated Email';

    case 'selectEmailTemplate':
      return 'Select Email Template';

    case 'linkedinConnection':
      return 'LinkedIn Connection';

    case 'linkedinViewProfile':
      return 'LinkedIn View Profile';

    case 'linkedinMail':
      return 'LinkedIn InMail';

    case 'linkedinMessage':
      return 'LinkedIn Message (Manual)';

    case 'linkedin':
      return 'LinkedIn';

    case 'call':
      return 'Call Back';

    case 'general':
      return 'To Do';

    case 'endsquence':
      return 'End Sequence';
    case 'sms':
      return 'SMS';
    default:
      return '';
  }
};

export const getCardBgColor = (type) => {
  switch (type) {
    case 'wait':
      return '#ECEFF1';
      break;

    case 'email':
      return '#E3F2FD';
      break;

    case 'selectEmailTemplate':
      return '#FCE4EC';
      break;

    case 'linkedinConnection':
    case 'linkedinViewProfile':
    case 'linkedinMail':
    case 'linkedinMessage':
    case 'linkedin':
      return '#1976D214';
      break;

    case 'call':
      return '#E8F5E9';
      break;

    case 'general':
      return '#E0F2F1';
      break;

    case 'endsquence':
      return '#FEEBEE';
      break;

    case 'sms':
      return '#EDE7F6';

    default:
      return null;
      break;
  }
};

export const getStepIcon = (type) => {
  switch (type) {
    case 'wait':
      return <WatchLaterIcon style={{ fill: '#78909C', width: 24, height: 24 }} />;
      break;

    case 'email':
      return <EmailIcon style={{ color: '#42A5F5' }} />;
      break;

    case 'selectEmailTemplate':
      return <ContactMailIcon style={{ color: '#EC407A' }} />;
      break;

    case 'linkedinConnection':
    case 'linkedinViewProfile':
    case 'linkedinMail':
    case 'linkedinMessage':
    case 'linkedin':
      return <LinkedInIcon style={{ color: '#1282B2' }} />;
      break;

    case 'call':
      return <PhoneCallbackIcon style={{ color: '#66BB6A' }} />;
      break;

    case 'general':
      return <img src={images.sequence.AddTaskFilled} style={{ width: '24px' }} />;
      break;

    case 'endsquence':
      return <LockIcon style={{ color: '#EF5350' }} />;
      break;

    case 'sms':
      return <SmsIcon style={{ fill: '#00bcd4' }} />;

    default:
      return null;
      break;
  }
};

export const getEnrollmentStepStatus = (task) => {
  const due = moment(task.dueOn).format(dateFormat);

  switch (task.status) {
    case 'completed':
      return `Completed`;

    case 'pending':
      return `Due - ${due}`;

    case 'hold':
      return `Not yet scheduled`;

    default:
      return `${capitalizeFirstLetter(task.status)}`;
  }
};

export const getEnrollmentStatusLabel = (status) => {
  switch (status) {
    case 'outOfOffice':
      return 'Out Of Office';

    case 'unsubscribed':
      return 'Unsubscribed';

    case 'interested':
      return 'Interested';

    case 'notInterested':
      return 'Not Interested';

    case 'inProgress':
      return 'In Progress';

    case 'hold':
      return 'On Hold';

    case 'completed':
      return 'Completed';

    case 'failed':
      return 'Failed';

    case 'bounced':
      return 'Bounced';

    default:
      return capitalizeFirstLetter(status);
  }
};

export const getColorCode = (status) => {
  switch (status) {
    case sequenceEnrollmentStatus.BOUNCED:
      return { color: '#FFFFFF', bgColor: '#FFA726' };

    case sequenceEnrollmentStatus.COMPLETED:
      return { color: '#FFFFFF', bgColor: '#2E7D32' };

    case sequenceEnrollmentStatus.HOLD:
      return { color: '#FFFFFF', bgColor: '#9C27B0' };

    case sequenceEnrollmentStatus.IN_PROGRESS:
      return { color: '#FFFFFF', bgColor: '#A5D6A7' };

    case sequenceEnrollmentStatus.UNSUBSCRIBED:
      return { color: '#FFFFFF', bgColor: '#F3AE59' };

    case sequenceEnrollmentStatus.INTERESTED:
      return { color: '#FFFFFF', bgColor: '#66BB6A' };

    case sequenceEnrollmentStatus.NOT_INTERESTED:
      return { color: '#FFFFFF', bgColor: '#D32F2F' };

    case sequenceEnrollmentStatus.NEVER:
      return { color: '#000000', bgColor: '#FFFFFF' };

    case sequenceEnrollmentStatus.REPLIED:
      return { color: '#000000', bgColor: '#2596be' };

    case sequenceEnrollmentStatus.FAILED:
      return { color: '#FFFFFF', bgColor: '#D32F2F' };

    case sequenceEnrollmentStatus.ERROR:
      return { color: '#FFFFFF', bgColor: '#D32F2F' };

    default:
      return { color: 'rgba(0, 0, 0, 0.87)', bgColor: 'rgba(0, 0, 0, 0.08)' };
  }
};
