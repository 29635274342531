

export const RoleplayTabs = [
    {
        id: "generalSettings",
        name: "General Settings",

    },
    {
        id: "availableRoleplays",
        name: "Available Roleplays"
    }
]

export const roleplaySearchTypes = [
    {
        label: 'Show All',
        value: 'alll',
    },
    {
        label: 'Show: Default Roleplays',
        value: 'default',
    },
    {
        label: 'Show: Modified Roleplays',
        value: 'custom',
    },
];

export const propertyDetails = [
    {
        id: "1",
        heading: "Techniques",
        description: "technique descriptions",
        addText: "Add new Technique",
        values: "technique_ids"
    },
    {
        id: "2",
        heading: "Objections",
        description: "objections descriptions",
        addText: "Add new Objections",
        values: "objection_ids"
    }
];


