import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import {
  Box,
  FormHelperText,
  Stack,
  ButtonBase,
  Popover,
  Switch,
  Menu,
  MenuItem,
} from '@mui/material';
import { Button, Form, Modal, Spacer, DateRangePickerAlt } from 'src/components/shared';
import Typography from '@mui/material/Typography';
import { useStyles } from '../ProfileIntegration/styles';
import { daysOfWeek } from 'src/config';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import { DayChip } from './styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import toast from 'src/utils/toast';
import { Accordion } from 'src/components/App';
import { hasIn } from 'lodash';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const defaultTime = {
  defaultStart: '9:00',
  defaultEnd: '17:00',
};

const autoSearchOtions = {
  workEmail: 'Work email',
  personalEmail: 'Personal email',
  mobile: 'Phone number',
};

const regex24hour = /^([01]\d|2[0-3]):?([0-5]\d)$/;

function SchedulingWindow({ editUser, status, formData, Container, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [holidayBtnLoading, setHolidayBtnLoading] = useState(false);
  const [popOverKey, setPopoverKey] = useState('');
  const [holidays, setHolidays] = useState(editUser?.holidaySettings || []);
  const [editHoliday, setEditHoliday] = useState(null);
  const [sequenceDelay, setSequenceDelay] = useState({
    sequenceOffset: hasIn(editUser, 'sequenceOffset') ? editUser?.sequenceOffset : false,
    sequenceOffsetinMinutes: hasIn(editUser, 'sequenceOffsetinMinutes')
      ? editUser?.sequenceOffsetinMinutes
      : 15,
  });
  const [autoSearchData, setAutoSearchData] = useState({
    autoEnrich: hasIn(editUser, 'autoEnrich') ? editUser?.autoEnrich : false,
    autoEnrichType: hasIn(editUser, 'autoEnrichType') ? editUser?.autoEnrichType : '',
  });
  const [deleteHolidayModal, setDeleteHolidayModal] = useState(null);
  const [currentUser, setCurrentUser] = useState({});
  const { defaultStart, defaultEnd } = defaultTime;

  const classes = useStyles();
  const holidayRef = useRef();
  holidayRef.current = editHoliday;

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setEditHoliday(null);
  };

  useEffect(() => {
    if (editUser && editUser?.id) {
      setCurrentUser(editUser);
    }
  }, [editUser]);

  const handleSubmit = async (values, form) => {
    let data = {
      schedulingWindow: { ...values },
      emailLimit: values.emailLimit,
    };

    if (editUser && editUser?.id) {
      const updatedRow = { id: editUser?.id, data: data };
      setLoading(true);
      await props.putUser(updatedRow);
      setLoading(false);
    }
  };

  const open = Boolean(anchorEl);

  const saveUserData = async (data) => {
    if (editUser && editUser?.id) {
      const updatedRow = {
        id: editUser?.id,
        data,
      };
      setHolidayBtnLoading(true);
      await props.putUser(updatedRow);
      setHolidayBtnLoading(false);
    }
  };

  const getStartTime = () => {
    const time = editUser?.schedulingWindow?.dailyWindow?.start;
    if (!time || time === 'Invalid Date') return defaultStart;
    else return time;
  };

  const getEndTime = () => {
    const time = editUser?.schedulingWindow?.dailyWindow?.end;
    if (!time || time === 'Invalid Date') return defaultEnd;
    else return time;
  };

  return (
    <>
      <Form
        initialValues={{
          weeklyWindow:
            editUser?.schedulingWindow?.weeklyWindow &&
            Array.isArray(editUser?.schedulingWindow?.weeklyWindow)
              ? editUser?.schedulingWindow?.weeklyWindow
              : [1, 2, 3, 4, 5],
          dailyWindow: {
            start: getStartTime(),
            end: getEndTime(),
          },
          emailLimit: currentUser.emailLimit || '',
        }}
        validationSchema={Yup.object().shape({
          emailLimit: Yup.number()
            .typeError("That doesn't look like a number")
            .positive("Limit can't start with a minus")
            .integer("Limit can't include a decimal point")
            .min(1)
            .required('An Email Limit is Required.'),
          weeklyWindow: Yup.array().test({
            message: () => 'Please choose at least one day.',
            test: (values) => {
              if (!values) return false;
              return values?.length !== 0; // empty {}
            },
          }),
          dailyWindow: Yup.object().test(
            'dailyWindow',
            'dailyWindow',
            async (values, { createError }) => {
              const today = moment().format('YYYY-MM-DD');
              const start = moment(today + ' ' + values.start);
              const end = moment(today + ' ' + values.end);
              if (!regex24hour.test(values.start))
                return createError({
                  message: 'Your start time must be of a valid format.',
                  path: 'dailyWindow',
                });

              if (!regex24hour.test(values.end))
                return createError({
                  message: 'Your end time must be of a valid format.',
                  path: 'dailyWindow',
                });

              if (start > end) {
                return createError({
                  message: 'Your start time must be before your end time.',
                  path: 'dailyWindow',
                });
              }
              const difference = moment.duration(end.diff(start)).asHours();
              if (difference < 7) {
                return createError({
                  message:
                    'Please choose a window of at least 7 hours between the start and date time.',
                  path: 'dailyWindow',
                });
                return false;
              }
              if (difference > 12) {
                return createError({
                  message:
                    'Please choose a window that does not exceed 12 hours between the start and date time.',
                  path: 'dailyWindow',
                });
                return false;
              }
              return true;
            },
          ),
        })}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              style={{ height: '100%' }}
              noValidate
            >
              <Accordion
                title={
                  <Stack direction="row" spacing={1}>
                    <Typography variant="h3" className={'accordion-title'}>
                      Outreach settings
                    </Typography>
                    <Box
                      onMouseEnter={(val) => {
                        setPopoverKey('settings');
                        handlePopoverOpen(val);
                      }}
                      onMouseLeave={handlePopoverClose}
                    >
                      <InfoIcon sx={{ fontSize: '20px', color: '#00000040' }} />
                    </Box>
                  </Stack>
                }
                subTitle="Choose the days of the week and the hours you want your email outreach to be
                    sent."
                sx={{
                  '&.MuiAccordion-root': {
                    boxShadow:
                      '0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033 !important',
                  },
                  '& .MuiAccordionSummary-root': {
                    borderBottom: 'none !important',

                    '& .accordion-title': {
                      color: '#000000 !important',
                      fontSize: '18px',
                      fontWeight: 500,
                    },
                  },
                }}
                icon={<ArrowRightIcon />}
              >
                <Container>
                  <Stack direction="row" spacing={2}>
                    {daysOfWeek.map((day, index) => {
                      return (
                        <ButtonBase
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            let newVal = [...values.weeklyWindow];
                            if (newVal.includes(day.value)) {
                              newVal = newVal.filter((id) => id !== day.value);
                            } else {
                              newVal.push(day.value);
                            }
                            formProps.setFieldValue('weeklyWindow', newVal);
                          }}
                          key={`outreach-day-${index}`}
                        >
                          <DayChip
                            label={day.label}
                            variant={
                              values.weeklyWindow.includes(day.value) ? 'contained' : 'outlined'
                            }
                            color={
                              values.weeklyWindow.includes(day.value) ? 'secondary' : 'default'
                            }
                          />
                        </ButtonBase>
                      );
                    })}
                  </Stack>

                  {formProps?.errors?.weeklyWindow && formProps?.errors?.weeklyWindow !== '' && (
                    <>
                      <Spacer x={1} y={1} />
                      <FormHelperText error>{formProps?.errors?.weeklyWindow}</FormHelperText>
                    </>
                  )}

                  <Spacer x={2} y={2} />
                  <Stack direction="row" spacing={2} alignItems="center" sx={{ wvalueth: 400 }}>
                    <Box flex={1}>
                      <Form.Field.Timepicker
                        options={daysOfWeek}
                        fullWidth
                        name="dailyWindow.start"
                        variant="outlined"
                        optLabel="name"
                        optValue="id"
                      />
                    </Box>
                    <Typography>to</Typography>
                    <Box mb={1} flex={1}>
                      <Form.Field.Timepicker
                        fullWidth
                        options={daysOfWeek}
                        name="dailyWindow.end"
                        variant="outlined"
                        optLabel="name"
                        optValue="id"
                      />
                    </Box>
                  </Stack>

                  {formProps?.errors?.dailyWindow && formProps?.errors?.dailyWindow !== '' && (
                    <FormHelperText error>{formProps?.errors?.dailyWindow}</FormHelperText>
                  )}

                  <Spacer y={2} />

                  <Typography variant="h2">Email sending limits</Typography>
                  <Spacer x={1} y={1} />
                  <Typography variant="body2" className={classes.headerSubTitle} mb={2}>
                    Protect your mailbox reputation by limiting the numbers of emails sent.
                  </Typography>

                  <Form.Field.Input
                    fullWidth
                    rows={4}
                    variant="outlined"
                    name="emailLimit"
                    label="Send Email Limit Per Day"
                  />
                  <Spacer x={1} y={1} />
                  <Button variant="contained" color="secondary" type="submit" loading={loading}>
                    Update
                  </Button>
                </Container>
              </Accordion>

              <Spacer y={4} />

              <Accordion
                title="Custom holidays"
                subTitle="Choose the holidays you want your email outreach to be paused."
                sx={{
                  '&.MuiAccordion-root': {
                    boxShadow:
                      '0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033 !important',
                  },
                  '& .MuiAccordionSummary-root': {
                    borderBottom: 'none !important',

                    '& .accordion-title': {
                      color: '#000000 !important',
                      fontSize: '18px',
                      fontWeight: 500,
                    },
                  },
                }}
                icon={<ArrowRightIcon />}
              >
                <Container>
                  <Box direction={'column'}>
                    <Stack direction="column" gap={'6px'} spacing={2}>
                      {holidays?.map((holiday, index) => {
                        return (
                          <Stack
                            direction={'row'}
                            marginTop={'0 !important'}
                            alignItems={'center'}
                            gap={'20px'}
                            key={index}
                          >
                            <Typography variant="subtitle1" color={'#000'}>
                              {moment(holiday?.eventDate).format('DD/MM/YYYY')} -{' '}
                              {moment(holiday?.eventEndDate).format('DD/MM/YYYY')}
                            </Typography>
                            <Button
                              iconButton={true}
                              edge="end"
                              aria-label="Delete date"
                              onClick={(val) => {
                                setPopoverKey('holiday');
                                setEditHoliday({
                                  index,
                                  startDate: new Date(holiday?.eventDate),
                                  endDate: new Date(holiday?.eventEndDate),
                                });
                                handlePopoverOpen(val);
                              }}
                            >
                              <EditIcon />
                            </Button>
                            <Button
                              iconButton={true}
                              edge="end"
                              aria-label="Delete date"
                              onClick={() => setDeleteHolidayModal(index?.toString())}
                            >
                              <DeleteIcon />
                            </Button>
                          </Stack>
                        );
                      })}
                    </Stack>
                    <Spacer y={2} />
                    <Button
                      variant="outlined"
                      color="secondary"
                      loading={holidayBtnLoading}
                      onClick={(val) => {
                        setPopoverKey('holiday');
                        handlePopoverOpen(val);
                      }}
                    >
                      + Add Holiday
                    </Button>
                    <Popover
                      open={open && popOverKey === 'holiday'}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <DateRangePickerAlt
                        toggle={handlePopoverClose}
                        initialDateRange={
                          holidayRef?.current?.startDate && holidayRef?.current?.endDate
                            ? {
                                startDate: holidayRef?.current?.startDate,
                                endDate: holidayRef?.current?.endDate,
                              }
                            : {}
                        }
                        minDate={new Date()}
                        onChange={(val) => {
                          if (
                            new Date(val.startDate).setHours(0, 0, 0, 0) <
                            new Date().setHours(0, 0, 0, 0)
                          )
                            return toast.error(`Please select a future date for the holiday`, 'tc');
                          const isValid = holidays.find((existingRange, index) => {
                            if (holidayRef?.current?.index === index) return false;
                            else if (
                              (new Date(existingRange?.eventDate).setHours(0, 0, 0, 0) >
                                new Date(val?.startDate).setHours(0, 0, 0, 0) ||
                                new Date(val?.startDate).setHours(0, 0, 0, 0) >
                                  new Date(existingRange?.eventEndDate).setHours(0, 0, 0, 0)) &&
                              (new Date(existingRange?.eventDate).setHours(0, 0, 0, 0) >
                                new Date(val?.endDate).setHours(0, 0, 0, 0) ||
                                new Date(val?.endDate).setHours(0, 0, 0, 0) >
                                  new Date(existingRange?.eventEndDate).setHours(0, 0, 0, 0)) &&
                              !(
                                new Date(existingRange?.eventDate).setHours(0, 0, 0, 0) >
                                  new Date(val?.startDate).setHours(0, 0, 0, 0) &&
                                new Date(val?.endDate).setHours(0, 0, 0, 0) >
                                  new Date(existingRange?.eventEndDate).setHours(0, 0, 0, 0)
                              )
                            ) {
                              return false;
                            } else return true;
                          });
                          if (!!isValid)
                            return toast.error(
                              'Selected date already exist, Please check existing holidays!',
                              'tc',
                            );
                          let updatedHolidays = [];
                          if (typeof holidayRef?.current?.index === 'number') {
                            const newData = [...holidays];
                            newData[holidayRef?.current?.index] = {
                              eventDate: moment(val?.startDate).format('YYYY-MM-DD'),
                              eventEndDate: moment(val?.endDate).format('YYYY-MM-DD'),
                            };
                            updatedHolidays = newData;
                          } else
                            updatedHolidays = [
                              ...holidays,
                              {
                                eventDate: moment(val?.startDate).format('YYYY-MM-DD'),
                                eventEndDate: moment(val?.endDate).format('YYYY-MM-DD'),
                              },
                            ];
                          setEditHoliday(null);
                          saveUserData({ holidaySettings: updatedHolidays });
                          setHolidays(updatedHolidays);
                          handlePopoverClose();
                        }}
                      />
                    </Popover>
                  </Box>
                  <Modal
                    open={deleteHolidayModal}
                    title="Remove holiday"
                    okText="wertyb "
                    onClose={() => setDeleteHolidayModal(false)}
                  >
                    <Typography variant="h4">
                      Are you sure you want to remove holiday? All your emails for the scheduled
                      period will be resumed.
                    </Typography>

                    <Spacer y={2} />

                    <Stack direction="row" gap={'24px'} justifyContent={'flex-end'}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setDeleteHolidayModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        loading={holidayBtnLoading}
                        onClick={async () => {
                          const newData = [...holidays];
                          newData.splice(Number(deleteHolidayModal), 1);
                          await saveUserData({ holidaySettings: newData });
                          setHolidays(newData);
                          setDeleteHolidayModal(false);
                        }}
                      >
                        Yes, Continue
                      </Button>
                    </Stack>
                  </Modal>
                </Container>
              </Accordion>

              <Spacer y={4} />

              <Accordion
                title={
                  <Stack direction="row" spacing={1}>
                    <Typography variant="h3" className={'accordion-title'}>
                      Sequence delay
                    </Typography>
                    <Box
                      onMouseEnter={(val) => {
                        setPopoverKey('settings');
                        handlePopoverOpen(val);
                      }}
                      onMouseLeave={handlePopoverClose}
                    >
                      <InfoIcon sx={{ fontSize: '20px', color: '#00000040' }} />
                    </Box>
                  </Stack>
                }
                subTitle="You can set a time delay for the first step of your sequence using the dropdown below."
                sx={{
                  '&.MuiAccordion-root': {
                    boxShadow:
                      '0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033 !important',
                  },
                  '& .MuiAccordionSummary-root': {
                    borderBottom: 'none !important',

                    '& .accordion-title': {
                      color: '#000000 !important',
                      fontSize: '18px',
                      fontWeight: 500,
                    },
                  },
                }}
                icon={<ArrowRightIcon />}
              >
                <Container>
                  <Stack direction={'row'} gap={'24px'} alignItems={'center'}>
                    <Switch
                      checked={sequenceDelay?.sequenceOffset}
                      color="secondary"
                      onClick={() =>
                        setSequenceDelay((prevValue) => {
                          return {
                            ...prevValue,
                            sequenceOffset: !sequenceDelay?.sequenceOffset,
                          };
                        })
                      }
                    />
                    <Button
                      sx={{
                        display: 'flex',
                        gap: '6px',
                        alignItems: 'center',
                        fontWeight: 400,
                        fontSize: 16,
                        color: '#000000DE',
                      }}
                      onClick={(val) => {
                        if (!sequenceDelay?.sequenceOffset) return null;
                        setPopoverKey('delay');
                        handlePopoverOpen(val);
                      }}
                    >
                      {sequenceDelay?.sequenceOffsetinMinutes < 60
                        ? sequenceDelay?.sequenceOffsetinMinutes
                        : sequenceDelay?.sequenceOffsetinMinutes / 60}{' '}
                      {sequenceDelay?.sequenceOffsetinMinutes === 60
                        ? 'Hour'
                        : sequenceDelay?.sequenceOffsetinMinutes < 60
                        ? 'Minutes'
                        : 'Hours'}{' '}
                      {sequenceDelay?.sequenceOffset && <ArrowDropDownOutlinedIcon />}
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted={false}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={open && popOverKey === 'delay' && sequenceDelay?.sequenceOffset}
                      onClose={handlePopoverClose}
                    >
                      {[15, 20, 30, 45, 60, 120, 180, 300, 480, 1440]?.map((time) => (
                        <MenuItem
                          onClick={() => {
                            setSequenceDelay((prevValue) => {
                              return {
                                ...prevValue,
                                sequenceOffsetinMinutes: time,
                              };
                            });
                            handlePopoverClose();
                          }}
                        >
                          {time < 60 ? time : time / 60}{' '}
                          {time === 60 ? 'hour' : time > 60 ? 'hours' : 'minutes'}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Stack>
                  <Spacer y={1.5} />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => saveUserData(sequenceDelay)}
                    disabled={!editUser?.sequenceOffset && !sequenceDelay?.sequenceOffset}
                  >
                    Save
                  </Button>
                </Container>
              </Accordion>

              <Spacer y={4} />

              <Accordion
                title="Auto-search data"
                subTitle="Automatically call the data waterfall when adding contacts to SuperReach."
                sx={{
                  '&.MuiAccordion-root': {
                    boxShadow:
                      '0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033 !important',
                  },
                  '& .MuiAccordionSummary-root': {
                    borderBottom: 'none !important',

                    '& .accordion-title': {
                      color: '#000000 !important',
                      fontSize: '18px',
                      fontWeight: 500,
                    },
                  },
                }}
                icon={<ArrowRightIcon />}
              >
                <Container>
                  <Stack direction={'row'} gap={'24px'} alignItems={'center'}>
                    <Switch
                      checked={autoSearchData?.autoEnrich}
                      color="secondary"
                      onClick={() =>
                        setAutoSearchData((prevValue) => {
                          return {
                            ...prevValue,
                            autoEnrich: !autoSearchData?.autoEnrich,
                          };
                        })
                      }
                    />
                    <Button
                      sx={{
                        display: 'flex',
                        gap: '6px',
                        alignItems: 'center',
                        fontWeight: 400,
                        fontSize: 16,
                        color: '#000000DE',
                      }}
                      onClick={(val) => {
                        if (!autoSearchData?.autoEnrich) return null;
                        setPopoverKey('auto-search');
                        handlePopoverOpen(val);
                      }}
                    >
                      {autoSearchData?.autoEnrichType
                        ? autoSearchOtions[autoSearchData?.autoEnrichType] || ''
                        : 'Select data type'}{' '}
                      {autoSearchData?.autoEnrich && <ArrowDropDownOutlinedIcon />}
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted={false}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={open && popOverKey === 'auto-search' && autoSearchData?.autoEnrich}
                      onClose={handlePopoverClose}
                    >
                      {Object.keys(autoSearchOtions)?.map((key) => (
                        <MenuItem
                          onClick={() => {
                            setAutoSearchData((prevValue) => {
                              return {
                                ...prevValue,
                                autoEnrichType: key,
                              };
                            });
                            handlePopoverClose();
                          }}
                        >
                          <Typography sx={{ color: 'black', '&:hover': { color: '#1976D2' } }}>
                            {autoSearchOtions[key]}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Stack>
                  <Spacer y={1.5} />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => saveUserData(autoSearchData)}
                    disabled={!editUser?.autoEnrich && !autoSearchData?.autoEnrich}
                  >
                    Save
                  </Button>
                </Container>
              </Accordion>

              <Spacer y={4} />
            </form>
          );
        }}
      </Form>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open && popOverKey === 'settings'}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ maxWidth: 400 }} p={2}>
          <Stack direction="row" spacing={1}>
            <InfoIcon />
            <Typography variant="h2" color="textPrimary">
              Changing your outreach settings
            </Typography>
          </Stack>

          <Spacer x={2} y={2} />

          <Typography variant="body2" color="textSecondary">
            If you change your outreach settings after enrolling prospects, please note that
            enrollments will be subject to change to accommodate your new settings. For example, any
            email outreach that now falls outside of your new scheduling window will be rescheduled.
          </Typography>
          <Spacer x={1} y={1} />
          <Typography variant="body2" color="textSecondary">
            If you have any questions about this, please get in touch with our support team.
          </Typography>
        </Box>
      </Popover>
    </>
  );
}

export default SchedulingWindow;
