import styled from 'styled-components';
import { Tabs, Tab } from '@mui/material';

import { makeStyles, withStyles } from '@mui/styles';

export const StyledTabs = withStyles({
  indicator: {
    backgroundColor: 'rgba(25, 118, 210, 1)',
  },
})(Tabs);

export const StyledTab = withStyles((theme) => ({
  root: {
    opacity: 1,
    height: '46px',
    minHeight: '46px',
    '&:hover': {
      opacity: 1,
    },
    '&$selected': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {},
    button: {
      opacity: 1,
    },
  },
  button: {},
}))((props) => <Tab disableRipple {...props} />);
