import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FilterBar } from 'src/components/App';
import { roleplaySearchTypes } from './config';
import { Spacer } from 'src/components/shared';
import useStyles from './style';
import RoleplayCard from 'src/modules/admin/components/AvailableRoleplay/RoleplayCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import useRequest from 'src/components/hooks/useRequest';
import { fetchRoleplay } from 'src/modules/admin/api/adminApi';
import Paging from 'src/components/App/Paging';
import images from 'src/config/images';
import styled from 'styled-components';

const DisIcon = styled.img`
  width: 48px;
`;

const RoleplayListing = () => {
    const classes = useStyles();
    const [filter, setFilter] = useState({});
    const [paging, setPaging] = useState({
        _from: 0,
        _size: 10,
    });

    const payload = {
        ...paging,
        ...filter,
        ...(filter?.view ? { type: filter?.view?.value } : {}),
    };
    delete payload.view;

    const [data, { loading, error, updateCache }] = useRequest(fetchRoleplay, payload);

    const RoleplayFilter = [
        {
            key: 'view',
            title: 'Show all',
            type: 'dropdown',
            remote: false,
            cancellable: true,
            icon: <FilterAltOutlinedIcon />,
            options: roleplaySearchTypes,
        },
    ];

    const handleChange = (e) => {
        setPaging((prev) => ({
            ...prev,
            _from: e,
        }));
    };

    const handleFilterChange = (key, value) => {
        setFilter({
            ...filter,
            [key]: value,
        });
    };

    const handleSearch = (value) => {
        setFilter({
            ...filter,
            _search: value,
        });
    };

    if (loading) <></>;
    return (
        <>
            <Box display="flex" flexDirection="column" height="100%">
                <Box display={"flex"} gap={"1rem"} alignItems={"center"}>
                    <DisIcon src={images.roleplay.roleplayBlack} />
                    <Typography variant="h4" gutterBottom>
                        Available Roleplays
                    </Typography>
                </Box>
                <Spacer x={1} y={1} />
                <Typography variant="body1" color="textSecondary">
                    Select from the sessions with AI Roleplay.
                </Typography>
            </Box>
            <Spacer x={2} y={2} />

            <FilterBar
                filters={RoleplayFilter}
                showSearch={true}
                appliedFilters={filter}
                onChange={handleFilterChange}
                onSearch={handleSearch}
            />

            <Spacer x={2} y={2} />
            <Box display="flex" flexWrap={'wrap'} width="100%" height="100%" gap="2rem">
                {data && data?.sessRoleplayData?.map((roleplay, id) => <RoleplayCard data={roleplay} />)}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Spacer x={2} y={2} />
                <Paging
                    size="small"
                    perPage={paging._size}
                    page={paging._from}
                    count={data?.total?.value}
                    onChange={handleChange}
                />
            </Box>
        </>
    );
};

export default RoleplayListing;
