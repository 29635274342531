import React, { useEffect } from 'react';
import { Container, Box, Stack, Typography, Link } from '@mui/material';
import images from 'src/config/images';

import { AuthContent, AuthContentDark, Logo, AuthBg, CopyRight, AuthBgSetup } from './styles';

function AuthLayout({ children, ...props }) {
  if (props.location.pathname.startsWith('/auth/setup')) {
    return (
      <Container id={'lms'} className={'Inter-font-family'} maxWidth={false} disableGutters>
        <AuthContentDark>
          <a target="_blank" href="https://superreach.com/">
            <img src={images.app.logoWhite} />
          </a>
          <Container maxWidth="md" sx={{ minHeight: 'calc(100vh - 120px)' }}>
            <Box width={'100%'}>{children}</Box>
          </Container>
          <AuthBgSetup src={images.auth.authBG} />
          <>
            <CopyRight style={{ color: '#B1B1B1' }}>
              © SuperReach {new Date().getFullYear()} - All rights reserved.
            </CopyRight>
          </>
        </AuthContentDark>
      </Container>
    );
  }

  return (
    <Container maxWidth={false}>
      <AuthContent>
        <a target="_blank" href="https://superreach.com/">
          <Logo />
        </a>
        <Box width={'100%'}>{children}</Box>
      </AuthContent>
      <AuthBg />
      <CopyRight>© SuperReach {new Date().getFullYear()} - All rights reserved.</CopyRight>
    </Container>
  );
}

export default AuthLayout;
