import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardContent, Box, Stack, Typography } from '@mui/material';
import { Spacer, Button } from 'src/components/shared';
import BillingAddressForm from './BillingAddressForm';

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { billingCountryList } from '../../config/countries';

function BillingDetailsCard({ customer, chargeBeeId }) {
  const [showForm, setShowForm] = useState(false);
  const tenant = useSelector((state) => state.app.tenant);

  const BillingItem = ({ title, value }) => {
    return (
      <Stack direction="row" mb={2}>
        <Box flex={1}>
          <Typography color="textSecondary">{title}</Typography>
        </Box>
        <Box flex={3}>
          <div dangerouslySetInnerHTML={{ __html: value }} style={{ lineHeight: '1.6em' }} />
        </Box>
      </Stack>
    );
  };

  const billingAddress = useMemo(() => {
    const billing = customer?.billing_address ?? {};
    return `${billing?.line1 ? billing.line1 + '<br/>' : ''}
    ${billing?.line2 ? billing.line2 + '<br/>' : ''}${billing?.city ? billing.city + '<br/>' : ''}${
      billing?.state ? billing.state + '<br/>' : ''
    }${billing?.zip ? billing?.zip + '<br/>' : ''} ${
      billingCountryList?.find((item) => item?.code === billing?.country)?.name || ''
    }`;
  }, [customer]);

  const billingContact = useMemo(() => {
    return `${customer?.billing_address?.first_name || ''} ${
      customer?.billing_address?.last_name || ''
    }<br />${customer?.billing_address?.email || ''}<br /> ${
      customer?.billing_address?.phone || ''
    }`;
  }, [customer]);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Stack
            direction="row"
            alignItems={'center'}
            justifyContent="space-between"
            marginBottom={'16px'}
          >
            <Typography color="textPrimary" sx={{ fontWeight: 500 }}>
              {showForm ? 'Edit' : ''} Billing Details
            </Typography>
            <Button iconButton onClick={toggleForm}>
              {showForm ? <CloseIcon /> : <EditIcon />}
            </Button>
          </Stack>

          {showForm ? (
            <BillingAddressForm
              customer={customer}
              chargeBeeId={chargeBeeId}
              onClose={toggleForm}
            />
          ) : (
            <>
              <BillingItem title="Company name" value={customer?.billing_address?.company || ''} />
              <BillingItem title="Billing address" value={billingAddress} />
              <BillingItem title="Billing contact" value={billingContact} />
              {/* <BillingItem title="Other billing" value={otherBilling} /> */}
              <BillingItem title="Currency" value={tenant?.billingCurrency || ''} />
              {customer?.billing_address?.country === 'GB' ? (
                <BillingItem
                  title="VAT"
                  value={customer?.vat_number_prefix + customer?.vat_number || ''}
                />
              ) : null}
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
}

export default BillingDetailsCard;
