import React, { useState } from 'react';
import { Card, CardContent } from '@mui/material';
import RoleplayListing from './RoleplayListing';
import { RoleplayTabs } from './config';
import { Tabs } from 'src/components/App';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Spacer } from 'src/components/shared';

const AvailableRoleplays = () => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState('availableRoleplays');

  const onTabChange = (tab) => {
    setCurrentTab(tab.id);
    if (tab.id === 'generalSettings') {
      history.push('setting');
    }
  };

  return (
    <>
      <Tabs tabs={RoleplayTabs} onChange={onTabChange} value={currentTab} />
      <Spacer x={2} y={2} />
      <Card>
        <CardContent>
          <RoleplayListing />
        </CardContent>
      </Card>
    </>
  );
};

export default AvailableRoleplays;
