import React, { useState, useEffect } from 'react';
import { AppGrid } from 'src/components/App';
import { useSequenceContactsColumns } from './useColumns';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { enrolmentStatusOptions, openRateOptions } from '../../config/index';
import { isEmpty } from 'lodash';

const SequenceContactsGrid = ({ sequencesContacts, seqId, contactsLoading, ...props }) => {
  const [rowsSelected, setRowsSelected] = useState([]);
  const { data, filters, paging, sort } = sequencesContacts;
  const contactColumns = useSequenceContactsColumns(data);

  useEffect(() => {
    props.fetchSequenceContacts(paging, filters, seqId, sort);
  }, [paging.pageNo, seqId]);

  const contactFilters = [
    {
      key: 'status',
      title: 'Status',
      type: 'dropdown',
      remote: false,
      options: enrolmentStatusOptions,
      optLabel: 'label',
      optValue: 'value',
      cancellable: true,
      isPrimary: true,
    },
    {
      key: 'enrolledBy',
      title: 'Enrolled by',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return fetchUsersLookup(value);
      },
    },
    {
      key: 'enrolledDate',
      title: 'Enrolled date',
      type: 'relativeDateRange',
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'thisWeek', name: 'This week' },
        { id: 'lastWeek', name: 'Last week' },
        { id: 'thisMonth', name: 'This month' },
        { id: 'lastMonth', name: 'Last month' },
        { id: 'last3Month', name: 'Last 3 months' },
      ],
      isPrimary: true,
    },
    {
      key: 'openRate',
      title: 'Open Rate (%)',
      type: 'dropdown',
      options: openRateOptions(),
      optLabel: 'label',
      optValue: 'value',
      cancellable: true,
      searchable: true,
      isPrimary: true,
    },
  ];

  const handleTableChange = ({ pageNo, perPage }) => {
    props.fetchSequenceContacts(
      {
        pageNo,
        perPage,
      },
      filters,
      seqId,
      sort,
    );
  };

  const onSort = (data) => {
    props.fetchSequenceContacts(paging, filters, seqId, data);
  };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        props.fetchSequenceContacts(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
          seqId,
          sort,
        );
      } else {
        props.fetchSequenceContacts(paging, { ...filters, [key]: value }, seqId, sort);
      }
    } else {
      delete filters[key];
      if(key === '_search' && !value) {
        filters["_search"] = "";
      }
      props.fetchSequenceContacts(paging, filters, seqId, sort);
    }
  };

  return (
    <>
      <AppGrid
        columns={contactColumns.map((col, index) => ({
          ...col,
          options: contactColumns?.columns?.length
            ? { ...col.options, ...contactColumns?.columns[index] }
            : col.options,
        }))}
        data={data}
        onTableChange={handleTableChange}
        onFilterChange={onFilterChange}
        onSort={onSort}
        loading={contactsLoading}
        filters={contactFilters}
        appliedFilters={filters}
        options={{
          ...(!isEmpty(sort) && {
            sortOrder: {
              name: sort?.name || 'none',
              direction: sort?.direction || 'none',
            },
          }),
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          rowsSelected: rowsSelected,
          count: paging.count,
          selectableRows: 'multiple',
          searchPlaceholder: 'Search contacts',
          search: true,
          searchText: filters?._search ? filters._search : '',
          onRowsSelect: (rowsSelected, allRows) => {
            setRowsSelected(allRows.map((row) => row.dataIndex));
          },
          textLabels: {
            body: {
              noMatch: 'Sorry, no matching records found. Please enroll contacts to the sequence.',
            },
          },
        }}
      />
    </>
  );
};

export default SequenceContactsGrid;
