import React, { useEffect } from 'react';
import { AppGrid } from 'src/components/App';
import { useColumns } from './useColumns';

import { useDataProviders } from '../../utils/billingStore';

function Invoices() {
  const {
    invoices,
    fetchInvoices,
    fetchInvoice,
    payNowInvoice,
    loading,
    appliedFilters,
    setAppliedFilters,
  } = useDataProviders();

  useEffect(() => {
    fetchInvoices();
  }, []);

  const columns = useColumns(invoices, fetchInvoice, payNowInvoice);

  const filtersAvailable = [
    {
      key: 'createdAtRange',
      title: 'Filter By Date',
      custom: true,
      type: 'relativeDateRange',
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'thisWeek', name: 'This week' },
        { id: 'lastWeek', name: 'Last week' },
        { id: 'thisMonth', name: 'This month' },
        { id: 'lastMonth', name: 'Last month' },
        { id: 'last3Month', name: 'Last 3 months' },
      ],
      isPrimary: true,
    },
    {
      key: 'status',
      title: 'Status',
      type: 'dropdown',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      options: [
        {
          label: 'Paid',
          value: 'paid',
        },
        {
          label: 'Pending',
          value: 'Pending',
        },
      ],
    },
  ];

  const handleTableChange = ({ pageNo, perPage }) => {
    setAppliedFilters((prevProps) => {
      return {
        paging: { pageNo, perPage },
        filters: prevProps.filters,
      };
    });
  };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      setAppliedFilters((prevProps) => {
        const { paging, filters } = prevProps;
        return {
          paging: {
            ...paging,
            pageNo: 0,
          },
          filters: { ...filters, [key]: value },
        };
      });
    } else {
      setAppliedFilters((prevProps) => {
        const { paging, filters } = prevProps;
        delete filters[key];
        return { paging, filters };
      });
    }
  };

  return (
    <>
      <AppGrid
        selectableRows="none"
        columns={columns}
        data={invoices}
        filters={filtersAvailable}
        appliedFilters={appliedFilters?.filters}
        onTableChange={handleTableChange}
        onFilterChange={onFilterChange}
        loading={loading.invoices}
        options={{
          pagination: false,
          page: appliedFilters?.paging?.pageNo,
          rowsPerPage: appliedFilters?.paging?.perPage,
          serverSide: true,
          searchPlaceholder: 'Search invoice',
          searchText: appliedFilters?.filters?._search ? appliedFilters?.filters._search : '',
          search: false,
          // page: paging.pageNo,
          // rowsPerPage: paging.perPage,
          // count: paging.count,
        }}
      />
    </>
  );
}

export default Invoices;
