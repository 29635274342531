import { Box, CardContent, Typography, Card } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Button, Spacer } from 'src/components/shared';
import SaveIcon from '@mui/icons-material/Save';
import ReplayIcon from '@mui/icons-material/Replay';
import RoleplayProperty from 'src/modules/admin/components/AvailableRoleplay/RoleplayProperty';
import { BackButton } from 'src/components/App';
import images from 'src/config/images';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { fetchSingleRoleplay, fetchSingleSession, testRoleplay, updateRoleplay } from 'src/modules/admin/api/adminApi';
import useRequest from 'src/components/hooks/useRequest';
import MissingImage from 'src/modules/admin/components/AvailableRoleplay/MissingImage';
import { propertyDetails } from './config';


const DisIcon = styled.img`
  width: 48px;
`;

const RoleplayImg = styled.img`
  width: 100%;
  aspect-ratio: 1/1;
  border-radius:16px;
  object-fit:cover
`;

const EditRoleplay = (props) => {
    const [roleplayData, { loading: rloading, error: rError, updateCache: updateCacheRoleplay }] = useRequest(fetchSingleRoleplay, props?.match?.params?.rid);
    const [sessionData, { loading: sloading, error: sError, updateCache: updateeCacheSession }] = useRequest(fetchSingleSession, props?.match?.params?.sid);
    const history = useHistory();
    const [updateValues, setUpdatedValues] = useState();
    const [resetDefault, setResetDefault] = useState(false);
    const [saveError, setSaveError] = useState({})

    const updatedRoleplay = useMemo(() => {
        if (resetDefault) {
            setResetDefault(false);
            setUpdatedValues()
            return roleplayData?.roleplay?.roleplay
        } else {
            if (updateValues) {
                return updateValues
            } else {
                return roleplayData?.roleplay?.roleplay
            }
        }
    }, [roleplayData, updateValues, resetDefault])

    const handleSaveRoleplay = async () => {
        if (updatedRoleplay?.objection_ids?.length < 3) {
            setSaveError(prev => ({
                ...prev,
                objections: true
            }))
            return;
        }
        let payload = {
            technique_ids: updatedRoleplay?.technique_ids.map((item) => item.id),
            objection_ids: updatedRoleplay?.objection_ids.map((item) => item.id)
        }
        try {
            let response = await updateRoleplay(payload, updatedRoleplay?.id);
            history.goBack()
        } catch (error) {
            console.error('Error: ', error);
        }
    }

    const testRoleplayHandler = async () => {
        await testRoleplay(sessionData?.session?.id, roleplayData?.roleplay?.roleplay?.id)
    }



    if (rloading && sloading) <Box></Box>
    if (updatedRoleplay) {
        return (
            <Card>
                <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <BackButton onClick={() => history.goBack()} />
                        <Button variant="contained" color={'secondary'} startIcon={<SaveIcon />} onClick={handleSaveRoleplay}>Save</Button>
                    </Box>
                    <Spacer x={4} y={4} />
                    <Box display="flex" justifyContent="space-between" gap={"1rem"}>
                        <Box width="15%">
                            {
                                sessionData?.session?.headerImage ? (
                                    <RoleplayImg src={sessionData?.session?.headerImage} alt="roleplay-img" />
                                ) : (
                                    <MissingImage title="Image not found" />
                                )
                            }
                        </Box>
                        <Box width="70%">
                            <Typography variant="h4" gutterBottom>{updatedRoleplay?.name || ""}</Typography>
                            <Typography variant="body1" color="textSecondary">
                                {updatedRoleplay?.name || ""}
                            </Typography>
                        </Box>
                        <Box display="flex" flexDirection={"column"} gap="2rem" width="15%">
                            <Button variant="outlined" color={'secondary'} startIcon={<DisIcon src={images.roleplay.roleplayGreen} />} onClick={testRoleplayHandler}>Test Roleplay</Button>
                            <Button variant="outlined" color={'secondary'} startIcon={<ReplayIcon />} onClick={() => setResetDefault(true)}>Reset Default</Button>
                        </Box>
                    </Box>
                    <Spacer x={2} y={2} />
                    {propertyDetails.map(item => (
                        <Box key={item.id}>
                            <RoleplayProperty
                                heading={item.heading}
                                description={item.description}
                                addText={item.addText}
                                values={updatedRoleplay[item.values]}
                                rid={props?.match?.params?.rid}
                                setUpdatedValues={setUpdatedValues}
                                updatedRoleplay={updatedRoleplay}
                                saveError={saveError}
                            />
                            <Spacer x={2} y={2} />
                        </Box>
                    ))}
                </CardContent>
            </Card>
        )
    } else {
        return <Box></Box>
    }
}


export default EditRoleplay