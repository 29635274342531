import { Box } from '@mui/material';
import React from 'react';
import { Form } from 'src/components/shared';
import { fetchTemplatesLookup } from 'src/modules/sequence/api/sequenceApi';

function LinkedIn({ event, formProps }) {
  return (
    <>
      <Box
        sx={{
          borderRight: '1px solid rgba(0,0,0,0.12)',
          borderLeft: '1px solid rgba(0,0,0,0.12)',
          borderBottom: '1px solid rgba(0,0,0,0.12)',
          // marginLeft: '-1px',
          // width: 'calc(100% + 1px)',
          '& .tox-tinymce': {
            borderRadius: '0 !important',
            borderLeft: 'none',
            borderRight: 'none',
            // borderTop: 'none',
          },
        }}
      >
        <Form.Field.AutoComplete
          multiple={false}
          fullWidth
          options={[event?.templates] || []}
          name={`${event.id}.template`}
          optLabel="name"
          optValue="id"
          placeholder="Template"
          remoteMethod={(val) => {
            return fetchTemplatesLookup(val, 'linkedin');
          }}
          size="small"
          sx={{
            marginTop: '-8px',
            marginBottom: '-17px',
          }}
          InputProps={{ sx: { padding: '6px 12px' } }}
          onChange={(val) => {
            formProps.setFieldValue(`${event.id}.subject`, val.subject);
            formProps.setFieldValue(`${event.id}.content`, val.content);
          }}
        />

        {event.eventType === 'linkedinMail' && (
          <Form.Field.Input
            fullWidth
            name={`${event.id}.subject`}
            placeholder="Subject"
            size="small"
            input
            showPicker={true}
            emojiPicker={true}
            InputProps={{
              sx: {
                padding: '6px 12px',
              },
            }}
            sx={{
              marginBottom: '-9px',
            }}
          />
        )}

        <Form.Field.TextEditor
          name={`${event.id}.content`}
          showFontStyle={false}
          showFontEditor={false}
          showAlignProps={false}
          showListProps={false}
          showAttachments={false}
          toolbarLocation="bottom"
          sx={{
            borderColor: '#cccccc',
            borderWidth: '1px',
            borderStyle: 'solid',
          }}
        />
      </Box>
    </>
  );
}

export default LinkedIn;
