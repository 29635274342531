export const filters = [
  {
    id: 0,
    label: 'Email',
    value: 'email',
    child: [
      {
        id: 11,
        label: 'Sent',
        value: 'Email_Sent',
      },
      {
        id: 12,
        label: 'Reply',
        value: 'Email_Reply',
      },
      {
        id: 13,
        label: 'Open',
        value: 'Email_Open',
      },
      {
        id: 14,
        label: 'Clicked',
        value: 'Email_Click',
      },
      {
        id: 15,
        label: 'Bounced',
        value: 'Email_Bounced',
      },
      {
        id: 16,
        label: 'Unsubscribed',
        value: 'Email_Unsubscribed',
      },
    ],
  },
  {
    id: 1,
    label: 'Linkedin',
    value: 'Linkedin',
    child: [
      {
        id: 17,
        label: 'ProfileView',
        value: 'Linkedin_Profile_Viewed',
      },
      {
        id: 18,
        label: 'Connection Request',
        value: 'Linkedin_Connection_Sent',
      },
      {
        id: 19,
        label: 'Inmail',
        value: 'Linkedin_Mail_Sent',
      },
      {
        id: 20,
        label: 'Message',
        value: 'Linkedin_Message_Sent',
      },
    ],
  },
  {
    id: 2,
    label: 'Sequence',
    value: 'Sequence',
    child: [
      {
        id: 21,
        label: 'Enrolled',
        value: 'Enrolled_In_Sequence',
      },
      {
        id: 22,
        label: 'Completed',
        value: 'Seq_Completed',
      },
      {
        id: 23,
        label: 'Pause',
        value: 'Seq_Hold',
      },
      {
        id: 28,
        label: 'Interested',
        value: 'interested',
      },
      {
        id: 29,
        label: 'Not Interested',
        value: 'Seq_Notinterested',
      },
      {
        id: 30,
        label: 'Removed',
        value: 'Contact_Removed_From_Sequence',
      },
    ],
  },
  {
    id: 3,
    label: 'General',
    value: 'General',
    child: [
      {
        id: 24,
        label: 'Calls',
        value: 'Call_Made',
      },
      {
        id: 25,
        label: 'SMS',
        value: 'Sms_Sent',
      },
      {
        id: 26,
        label: 'Assigned',
        value: 'Assigned',
      },
      {
        id: 27,
        label: 'Status Change',
        value: 'Status Change',
      },
    ],
  },
];
